import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { GalleryModule } from '@ngx-gallery/core';
import { GallerizeModule } from  '@ngx-gallery/gallerize';
import { LIGHTBOX_CONFIG } from '@ngx-gallery/lightbox';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LightboxModule } from  '@ngx-gallery/lightbox';

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
// import pipe
import { TranslateStringPipe } from './translate-string.pipe';
import { GetimageurlPipe } from './getimageurl.pipe'; 
@NgModule({
  declarations: [
    AppComponent,
    TranslateStringPipe,
    GetimageurlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    LeafletModule.forRoot(),
    GalleryModule,
    GallerizeModule,
    LightboxModule,
    CommonModule,
    ModalModule.forRoot(),
     // ngx-translate and the loader module
     HttpClientModule,
     TranslateModule.forRoot({
         loader: {
             provide: TranslateLoader,
             useFactory: HttpLoaderFactory,
             deps: [HttpClient]
         }
     })
  ],
  providers: [
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false
      }
    }
  ],
  exports:[
     TranslateStringPipe,
     GetimageurlPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
