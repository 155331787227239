import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const routes = {
  quote: () => `https://haproxy.abspos.pl/api/restaurant`
};

export class APIRestaurantContext {
  address: {
    city: string;
    country: string;
    county: string;
    latlng: string;
    level: string;
    map_link: string;
    number: string;
    post_code: string;
    radius: string;
    street: string;
  };
  name: string;
  local_host: string;
  local_type: string;
  id: string;
  delivery: boolean;
  delivery_price: number;
  delivery_price_mode: number;
  festival_smak: boolean;
  description: string;
  remote_order: boolean;
  work_time: any;
  menu: any;
  image: string;
  image_object: any;
  logo: string;
  geo: {
    lat: number;
    lon: number;
  };
  slug: string;
  category: any;
  products: any;
  text: any;
  length: number;
}
@Injectable({
  providedIn: 'root'
})
export class DataService {
  get(url: string) {
    throw new Error("Method not implemented.");
  }
  constructor(private httpClient: HttpClient) {}


  getPlaceQuote(slug: string) {
    const data: any = {
      slug: slug
    };
    
    return this.httpClient
    
      .post<APIRestaurantContext>(routes.quote(), data)
      .pipe(
        map((body: APIRestaurantContext) => body),
        catchError(() => of('Error, could not load ...'))
      );
  }
}