import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getImageUrl',
  pure: true
})
export class GetimageurlPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return this.getImageUrl(value);
  }
  getImageUrl(url: string): string {
    return url.includes('http') ? url : 'https://haproxy.abspos.pl/media/' + url;
  }
}
