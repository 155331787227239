import { Component,TemplateRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { icon, latLng, marker, tileLayer } from 'leaflet';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';
import { DataService,  APIRestaurantContext } from './data.service';
import { finalize } from 'rxjs/operators';
import { I18nService } from '../app/i18n.service';
import { environment } from '../../src/environments/environment';
import { merge, from } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { untilDestroyed } from './until-destroyed';


// Declare var for lightbox
declare var $:any;
///////////////////////////----> Smooth Scrolling
$(document).ready(function(){
  // Add smooth scrolling to all links in navbar + footer link
  $(".navbar a, .col-md-2 a,.col-md-3 a, footer a[href='#myPage']").on('click', function(event) {

   // Make sure this.hash has a value before overriding default behavior
  if (this.hash !== "") {

    // Prevent default anchor click behavior
    event.preventDefault();

    // Store hash
    var hash = this.hash;

    // Using jQuery's animate() method to add smooth page scroll
    // The optional number (900) specifies the number of milliseconds it takes to scroll to the specified area
    $('html, body').animate({
      scrollTop: ($(hash).offset().top)
    }, 600, function(){

      // Add hash (#) to URL when done scrolling (default click behavior)
      window.location.hash = hash;
      });
    } // End if
  });
})
///////////////////////////
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  place: APIRestaurantContext;  

  layers: any = [];
 
  options = {
    layers: [tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })],
    zoom: 12,
    center: latLng(50.3231705,20.0348875)
  };
  route: any;

  useLanguage(language: string) {
    this.translate.use(language);
}

  //modal ngx
  modalRef: BsModalRef;
  ////////////////////
  config = {
    class: 'modal-dialog-centered'
   
  }
  constructor(
    private router: Router,
    private modalService: BsModalService,
    private translate: TranslateService,
    private _http: DataService,
    private i18nService: I18nService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    
  
    ) { translate.setDefaultLang('pl'); }
 
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template, 
      this.config
      );
  }


  ngOnInit(){
    
     // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

     // Change page title on navigation or language change, based on route data
     merge(this.translate.onLangChange, onNavigationEnd)
     .pipe(
       map(() => {
         let route = this.activatedRoute;
         while (route.firstChild) {
           route = route.firstChild;
         }
         return route;
       }),
       filter(route => route.outlet === 'primary'),
       switchMap(route => route.data),
       untilDestroyed(this)
     )
     .subscribe(event => {
       const title = event.title;
       if (title) {
         this.titleService.setTitle(this.translate.instant(title));
       }
     });
 

    // Invoke lightbox on click
    $(document).on('click', '[data-toggle="lightbox"]', function(event) {
      event.preventDefault();
      $(this).ekkoLightbox();
  });
  this._http
    .getPlaceQuote('karczmajaksice')
    .pipe(
      finalize(() => {
        // this.isLoading = false;
      })
      )
      .subscribe((quote: APIRestaurantContext) => {
        console.log(quote);
        this.place = quote;
              this.options = {
                layers: [tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })],
                zoom: 13,
                center: latLng(50.3231705,20.0348875)
              };
      
              this.layers.push(
                marker([50.3231705,20.0348875], {
                  icon: icon({
                    iconSize: [25, 41],
                    iconAnchor: [13, 41],
                    iconUrl: 'assets/marker-icon.png',
                    shadowUrl: 'assets/marker-shadow.png'
                  })
                })
              )
    })

    
  }
  ngOnDestroy() {
    this.i18nService.destroy();
  }
  setLanguage(language: string) {
    this.i18nService.language = language;
  }
  get currentLanguage(): string {
    return this.i18nService.language;
  }
  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }  
}

