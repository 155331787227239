import { Pipe, PipeTransform } from '@angular/core';



@Pipe({
  name: 'translateString'
})
export class TranslateStringPipe implements PipeTransform {
  default: string;
  ret: string;

  transform(value: any[], local?: string, slice?: number): string {
    this.default = '';
    this.ret = '';

    for (const row of value) {
      if (row.default === true) {
        this.default = row.text;
      }
      if (row.locale === local) {
        this.ret = row.text;
      }
    }
    //log.info(`RETURN STRING ${this.ret} ${this.default}`);
    if (this.ret) {
      if (slice && this.ret.length > slice) {
        this.ret = this.ret.slice(0, slice) + '...';
      }

      return this.ret.slice(0, slice);
    } else {
      if (slice && this.default.length > slice) {
        this.default = this.default.slice(0, slice) + '...';
      }
      return this.default;
    }

  }
}
